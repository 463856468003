html,
body {
  margin: 0;
  font-family: "Roboto" !important; }

a {
  text-decoration: none;
  color: #2f628a; }

.MuiButtonBase-root a {
  color: white !important; }

.s12 {
  font-size: 12px !important; }

.s14 {
  font-size: 14px !important; }

.s16 {
  font-size: 16px !important; }

.flex { 
  display: flex !important;}

.flex-column {
  flex-direction: column !important;
}

.align-items-center {
  align-items: center !important;
}

.text-align-center {
  text-align: center !important;
}

.justify-content-center {
  justify-content: center !important;
}
.space {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.spaceBig {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.spaceBigX {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.spaceLeft {
  margin-left: 30px !important; }

.spaceBottom {
  margin-bottom: 20px !important; }

.spaceBottomX {
  margin-bottom: 40px !important; }

.padding {
  padding: 20px !important; }

.paddingBig {
  padding: 40px !important; }

.padding-small {
  padding: 5px !important; }

.b-right {
  border-right: 1px solid #f6f6f6; }

.b-bottom {
  border-bottom: 1px solid #f6f6f6; }

.p-top {
  padding-top: 5px; }

.p-bottom {
  padding-bottom: 5px; }

.m-left {
  margin-left: 20px !important; }

.header-text {
  text-align: center;
  font-size: 28px !important;
  font-weight: 500 !important; }

.blue {
  color: #2f628a !important; }

.green {
  color: #97ce68 !important; }

.red {
  color: #e75926 !important; }

.gray-2 {
  color: #4F4F4F !important;
}

.gray-3 {
  color: #828282 !important;
}
.yellowBg {
  background-color: #f2cb64 !important; }
  .yellowBg .MuiLinearProgress-bar {
    background-color: #f2cb64 !important; }

.sunshineBg {
  background-color: #F9AB2E !important;
}

.sunshineBg .MuiLinearProgress-bar {
  background-color: #F9AB2E !important;
}
.soulstine-blueBg {
  background-color: #0d44ac !important;
}
.darkblueBg {
  background-color: #446cb3 !important; }
  .darkblueBg .MuiLinearProgress-bar {
    background-color: #446cb3 !important; }

.lightblue {
  color: #609edf !important; }

.darklightblue {
  color: #023E8A !important
}

.darkblue {
  color: #446cb3 !important; }

.yellow {
  color: #f2cb64 !important; }

.white {
  color: #ffffff !important; }

.center {
  text-align: center; }

.bold {
  font-weight: bold !important; }

.dim {
  color: #666 !important; }

.small {
  font-size: 12px !important; }

.banner {
  width: 100%; }

.loading {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
  padding-top: 10vh;
  width: 100%; }

.loading-short {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 30vh;
  padding-top: 10vh;
  width: 100%; }

.success-alert {
  background-color: #2f628a;
  padding: 20px;
  color: white;
  border-radius: 3px; }

#root .MuiAppBar-colorPrimary {
  background-color: white;
  color: grey; }

#header {
  display: flex;
  flex: 1; }
  @media only screen and (max-width: 600px) {
    #header .MuiToolbar-gutters {
      justify-content: space-between;
      align-items: center; }
      #header .MuiToolbar-gutters .menu-icon {
        color: #30bafb; }
      #header .MuiToolbar-gutters .MuiSvgIcon-root {
        font-size: 2.5rem; } }
  #header #menu-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row; }
    #header #menu-right a {
      margin-right: 15px;
      margin-left: 15px;
      font-size: 0.9rem;
      color: #335f8c; }
    #header #menu-right .MuiButton-label {
      font-size: 0.9rem !important;
      color: #609edf; }
  #header #form-dialog-title {
    text-align: center; }

#dashboard-menu .MuiSvgIcon-root {
  margin-right: 10px !important; }

#dashboard-menu .MuiListItem-root {
  padding: 10px 20px; }

#dashboard-menu .menu-link a {
  color: #2f628a !important;
  display: flex; }

.dialog-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

#home .carousel .slide {
  background: transparent; }

#home #home-1 {
  position: relative; }
  #home #home-1 .slider-wrapper {
    height: 650px !important; }
  #home #home-1 h2,
  #home #home-1 h3,
  #home #home-1 h4 {
    color: #ffffff;
    background-color: transparent;
    text-shadow: 2px 2px 4px black; }
  #home #home-1 #text-1 {
    position: absolute;
    left: 250px;
    bottom: 450px; }
  #home #home-1 #text-2 {
    position: absolute;
    left: 200px;
    bottom: 75px; }
  #home #home-1 #text-3 {
    position: absolute;
    right: 180px;
    bottom: 200px; }

#home #home-2 {
  margin-top: 40px; }

@media only screen and (max-width: 600px) {
  #home .carousel .slide {
    background: transparent; }
  #home #home-1 {
    position: relative; }
    #home #home-1 .slider-wrapper {
      height: 400px !important; }
    #home #home-1 h2,
    #home #home-1 h3,
    #home #home-1 h4 {
      color: #ffffff;
      background-color: transparent;
      text-shadow: 2px 2px 4px black; }
    #home #home-1 #text-1 {
      position: absolute;
      left: 10px;
      bottom: 40px; }
      #home #home-1 #text-1 .title {
        font-size: 24px; }
      #home #home-1 #text-1 .subtitle {
        font-size: 16px; }
    #home #home-1 #text-2 {
      position: absolute;
      left: 10px;
      bottom: 40px; }
      #home #home-1 #text-2 .title {
        font-size: 24px; }
      #home #home-1 #text-2 .subtitle {
        font-size: 16px; }
    #home #home-1 #text-3 {
      position: absolute;
      left: 10px;
      bottom: 40px;
      right: 0px; }
      #home #home-1 #text-3 .title {
        font-size: 24px; }
      #home #home-1 #text-3 .subtitle {
        font-size: 16px; } }

#footer {
  width: 100%;
  z-index: 100;
  background-color: #333d50;
  padding: 75px 0 0 0;
  color: #fff; }

#footer-inner {
  width: 940px;
  margin: 0 auto;
  max-width: 96%;
  padding: 0 0 20px 0; }

#footer-main {
  width: 100%;
  zoom: 1;
  position: relative; }
  #footer-main .foot-header.foot-header-2 {
    margin-bottom: 35px !important; }

@media only screen and (max-width: 600px) {
  #footer-main {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: center; }
    #footer-main #footer-main-1 {
      width: 49%;
      text-align: center;
      min-height: 200px;
      margin-top: 20px; }
    #footer-main #footer-main-2 {
      width: 49%;
      text-align: center;
      min-height: 200px;
      margin-top: 20px; }
    #footer-main #footer-main-3 {
      width: 49%;
      text-align: center;
      min-height: 200px;
      margin-top: 20px; }
    #footer-main #footer-main-5 {
      width: 49%;
      text-align: center;
      min-height: 200px;
      margin-top: 20px; }
    #footer-main #footer-main-4 {
      width: 49%;
      text-align: center;
      min-height: 200px;
      margin-top: 20px; } }

#footer-main:before,
#footer-main:after {
  content: "";
  display: table; }

#footer-main:after {
  clear: both; }

.foot-header {
  color: #fff;
  margin: 0 0 35px 0 !important;
  font-size: 20px;
  text-transform: uppercase; }

.footer-body {
  color: #cccccc !important; }

.sc-ln-icon {
  margin: 0 5px; }

#footer-main a {
  color: #fff;
  text-decoration: none;
  font-size: 13px; }

#footer-main p {
  margin: 0 0 8px 0;
  line-height: 35px; }

#footer-main h3 {
  font-size: 16px;
  text-transform: uppercase; }

#footer-main a:hover {
  text-decoration: underline; }

#footer-main-1 {
  float: left;
  width: 20%; }

#footer-main-1-logo {
  width: 340px; }

#footer-main-2 {
  float: left;
  width: 20%; }

#footer-main-3 {
  float: left;
  width: 20%; }

#footer-main-5 {
  float: left;
  width: 20%; }

#footer-main-4 {
  float: left;
  width: 20%; }

#footer-main-4 img {
  max-width: 30%;
  cursor: pointer; }

#footer-disclaimer p {
  width: 94%;
  position: relative;
  font-size: 11px !important;
  text-align: justify; }

#footer-diclaimer-header {
  margin: 0 0 10px 0; }

#disclaimer-content {
  color: #cccccc;
  margin: 30px 0 0 0;
  padding: 0 0 20px 0; }

#disclaimer_button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 28px;
  text-align: center;
  cursor: pointer; }

#footer-copy p {
  width: 90%;
  font-size: 11px !important;
  margin: 75px 0 0 0; }

#ssl-con {
  margin-top: 20px;
  /* padding: 0 0 0 13.5px; */ }

.ssl_logo_new {
  margin-top: 20px;
  /* padding: 0 0 0 53px; */ }

#ssl-img {
  width: 100px;
  height: auto !important; }

.home-con {
  width: 100%;
  padding: 40px 0 0 0; }

#home-3 {
  text-align: center;
  background-size: cover;
  position: relative;
  background-position: center;
  background-image: url("images/home/whatwedo.jpg"); }

#home-3-inner {
  width: 500px;
  max-width: 96%;
  margin: 0 auto;
  padding: 0 0 50px 0; }

#home-3-what-we-do {
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  margin: 50px 0 30px 0; }

#home-3-what-we-do p {
  margin: 0 0 20px 0;
  text-align: center; }

#home-4 {
  text-align: center;
  background-size: cover;
  position: relative;
  background-position: center;
  background-image: url("images/home/bricks.jpg");
  margin: 0;
  padding: 40px 0; }

.home-4-divs {
  text-align: center;
  margin: 20px 0px;
  padding: 0 10px; }

.home-4-list-top {
  width: 50%;
  margin: auto; }

#home-5 {
  background-color: #325f8c;
  position: relative;
  background-size: auto 100%;
  background-image: url("images/home/bg-arr-blue.png");
  background-repeat: no-repeat;
  color: white; }
  #home-5 #home-5-inner {
    width: 500px;
    max-width: 96%;
    margin: 0 auto;
    padding: 40px 0 100px 0; }
  #home-5 .text {
    margin: 60px auto 20px; }
    #home-5 .text .title {
      font-size: 16px; }
    #home-5 .text .subtitle {
      font-size: 16px;
      font-weight: 500 !important;
      margin: 20px auto; }
  #home-5 .profile {
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white; }
  #home-5 .home-5-testimonial {
    position: relative;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    margin: 50px 0;
    text-align: center; }

#home-6 {
  padding-top: 50px;
  text-align: center;
  padding-bottom: 10px;
  position: relative; }
  #home-6 .home-6-img {
    width: 23%;
    display: inline-block;
    margin: 30px auto; }
  @media only screen and (max-width: 600px) {
    #home-6 .home-6-img {
      width: 46%;
      display: inline-block;
      margin: 30px auto; } }

#home-7 {
  background-color: #f2f2f2;
  position: relative;
  padding: 50px 0;
  text-align: center; }
  #home-7 #home-7-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly; }
  #home-7 .recongTextsh {
    text-align: center;
    line-height: 20px;
    margin-top: 10px; }
  #home-7 .recongTexts {
    text-align: center;
    line-height: 20px;
    margin-top: 3px; }
  #home-7 .recongDivs {
    width: 30%;
    display: inline-block;
    margin: 10px auto; }

@media (max-width: 600px) {
  #home-7 {
    background-color: #f2f2f2;
    position: relative;
    padding: 50px 0;
    text-align: center; }
    #home-7 #home-7-div {
      display: flex;
      align-items: center;
      flex-direction: column; }
    #home-7 .recongTextsh {
      text-align: center;
      line-height: 20px;
      margin-top: 10px; }
    #home-7 .recongTexts {
      text-align: center;
      line-height: 20px;
      margin-top: 3px; }
    #home-7 .recongDivs {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column; } }

#home-8 {
  text-align: center;
  background-color: white;
  position: relative;
  padding: 50px 0;
  display: inline-block;
  text-align: center; }

#home-8-inner {
  display: inline-block;
  width: 100%; }
  @media only screen and (max-width: 600px) {
    #home-8-inner .MuiGrid-align-items-xs-center {
      flex-direction: column; } }

#home-8Img {
  text-align: right;
  padding: 15px;
  padding-right: 40px; }

#home-8Text {
  text-align: left;
  font-size: 16px;
  padding: 28px; }

#about #about-us {
  margin-bottom: 100px; }
  #about #about-us h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px; }
  #about #about-us p {
    margin-bottom: 10px; }

#about #team {
  text-align: center;
  background: url("images/about/team.png") no-repeat;
  background-size: cover;
  padding: 50px 0; }
  #about #team .about-2-team-each {
    vertical-align: top;
    margin: 20px auto; }
    #about #team .about-2-team-each .name {
      font-size: 22px;
      color: #333d50; }
    #about #team .about-2-team-each .title {
      color: #1f497d;
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: bold; }
  #about #team .about-2-team-pic {
    width: 200px;
    margin: auto; }

@media only screen and (max-width: 600px) {
  #about .MuiGrid-container {
    flex-direction: column; } }

#get-funded #banner {
  margin-bottom: 50px; }

#get-funded h1 {
  margin-bottom: 30px; }

#get-funded #left-inner {
  padding-right: 30px; }

#get-funded #right-inner {
  padding-left: 30px; }

#get-funded #container {
  padding: 20px 0 100px; }

#how-it-works #banner {
  text-align: center;
  background: url("images/how-it-works/banner.jpg"); }
  @media only screen and (max-width: 600px) {
    #how-it-works #banner iframe {
      width: 90%;
      padding: 20px; } }

#how-it-works #container {
  margin: 50px auto; }
  @media only screen and (max-width: 600px) {
    #how-it-works #container .how-it-works .how-option {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; }
      #how-it-works #container .how-it-works .how-option .how {
        width: 45%;
        margin: 5px;
        height: 35px;
        border: 2px solid #98b1ca;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px; } }

#how-it-works .tab {
  margin: 40px auto; }
  #how-it-works .tab .grid {
    margin: 10px auto; }
    #how-it-works .tab .grid .img {
      text-align: center;
      height: 200px;
      margin: auto; }
    #how-it-works .tab .grid .bordered {
      text-align: center;
      padding: 20px 10px;
      background: #f2f5f7;
      font-weight: bold;
      color: #35608d !important; }
    #how-it-works .tab .grid ul p {
      color: #35608d !important; }
  @media only screen and (max-width: 600px) {
    #how-it-works .tab .grid {
      margin: 0px; } }

#media {
  padding: 30px 0;
  text-align: center; }
  #media img {
    margin: auto;
    width: 80%;
    margin: 20px auto; }

#career {
  padding-bottom: 30px; }

#partner .img-grid {
  text-align: center; }
  #partner .img-grid img {
    width: 60%;
    margin: auto; }

#blogs {
  padding-bottom: 100px; }
  #blogs .blog-div {
    margin: 30px auto; }
    #blogs .blog-div img {
      width: 100%; }

#blog {
  padding: 50px 0; }
  #blog .tags {
    cursor: pointer;
    margin-right: 10px; }

.progress {
  height: 15px !important;
  background-color: #6b7f8b !important;
  border-radius: 3px !important; }

.campaign-container .background {
  height: 180px;
  background-size: cover; }

.campaign-container .text-content {
  padding: 10px 20px 0 15px; }

.campaign-container .brief-info {
  text-align: center;
  width: 100%;
  border-spacing: 0; }

#campaigns #banner {
  background: url("images/campaign.jpg") no-repeat;
  background-size: cover;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center; }
  #campaigns #banner #filter {
    background: white;
    width: 70%;
    height: 150px;
    border-radius: 10px;
    border: 2px solid #e6e6e6;
    box-shadow: 3px 3px 5px #e6e6e6; }
    #campaigns #banner #filter #filter-content {
      padding: 10px 20px; }

#campaign #description img {
  width: 100%; }

#campaign #description .ql-video {
  width: 100%;
  height: 500px; }

#campaign #campaign-content #banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 600px; }
  #campaign #campaign-content #banner #overlay {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.4; }
  #campaign #campaign-content #banner #banner-title {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 150px;
    padding: 20px 20px 30px 20px; }

#campaign #campaign-content #overview {
  padding: 20px 0; }

#campaign #campaign-content #gallery img {
  cursor: pointer; }

#campaign #other-project {
  background: #f2f2f2;
  padding: 50px 0; }

#campaign .ql-font-open-sans {
  font-family: "Open Sans" !important; }

#campaign .ql-font-roboto {
  font-family: "Roboto" !important; }

#invest-table {
  width: 100%; }
  #invest-table td {
    width: 33%;
    text-align: center; }

#dashboard-component {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #3f4a59;
  color: white;
  overflow: hidden; }
  #dashboard-component a {
    color: white; }
  #dashboard-component .hover {
    background-color: #33cabb; }

#dashboard {
  background-color: #f5f5f5;
  padding-top: 30px; }
  #dashboard .MuiGrid-item {
    margin: auto; }
  #dashboard .MuiGrid-spacing-xs-4 {
    margin: 0 !important; }
  #dashboard .grids {
    width: 90% !important;
    padding: 5% 5%;
    color: white;
    width: 100%; }
  #dashboard #kb-wallet-sec {
    background-color: #274060; }
  #dashboard #xfers {
    background-color: #395b99; }
  #dashboard #total {
    background-color: #3fa5ea; }
  #dashboard #average {
    background-color: #42cafd; }

#portfolios {
  background-color: #f5f5f5;
  min-height: 100vh; }
  #portfolios .MuiTabs-flexContainer {
    background: white; }

#portfolio,
#portfolio-thankyou {
  background-color: #f5f5f5;
  min-height: 100vh; }

#campaign-updates {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-top: 20px; }
  #campaign-updates .MuiPaper-root .padding-small {
    text-align: center; }
  #campaign-updates .MuiPaper-root img {
    border-radius: 3px; }

#campaign-update,
#profile,
#bank-account,
#project,
#kb-wallet {
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-top: 20px; }

#profile .MuiPaper-root {
  padding: 20px 10px; }

#profile .MuiGrid-spacing-xs-5 > .MuiGrid-item {
  padding-bottom: 0;
  padding-top: 0; }
  #profile .MuiGrid-spacing-xs-5 > .MuiGrid-item .padding {
    padding: 10px !important; }

#verify,
#forget {
  background-color: #f5f5f5;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.fb-button {
  width: 100%;
  border: none;
  padding: 10px;
  color: white;
  background-color: #3b5998;
  font-size: 14px;
  margin: 10px auto;
  cursor: pointer; }

.fade-enter {
  opacity: 0;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in; }

.campaign-block h3 {
  font-size: 16px; }

.campaign-block p {
  font-size: 14px; }

.MuiDrawer-root {
  z-index: 1000 !important; }
  .MuiDrawer-root .MuiDrawer-paper {
    width: 100%;
    margin-top: 56px;
    align-items: center; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.pl-1 {
  padding-left: 1rem; }

.newPage {
  background-color: #333d50; }

.newPage-paper {
  border-radius: 0 !important;
  padding: 5rem 2rem;
  min-height: 500px;
  background-color: #fcfcfc !important;
  color: #4d4d4d !important; }

.newPage-navBar {
  padding: 0 !important;
  cursor: pointer;
  box-shadow: 0px 3px 0px 0px lightgrey; }

.newPage-navItem {
  padding: 0 !important;
  background-color: white;
  border-bottom: 1px solid #f2f2f2; }
  .newPage-navItem .newPage-navItemIcon, .newPage-navItem .newPage-navItemText {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0; }
  .newPage-navItem .newPage-navItemIcon {
    background-color: #fafafa;
    border-right: 1px solid #f2f2f2; }
  .newPage-navItem .newPage-navItemText {
    padding-left: 1rem;
    transition: .5s; }
  .newPage-navItem .newPage-navItemBadge {
    margin-left: 5px;
    color: #a6a6a6;
    padding: 2px 4px;
    border-radius: 2px;
    transition: .5s; }
  .newPage-navItem:hover .newPage-navItemIcon {
    background-color: #609edf;
    color: white; }
  .newPage-navItem:hover .newPage-navItemText {
    padding-left: 2rem; }
  .newPage-navItem:hover .newPage-navItemBadge {
    background-color: #609edf;
    color: white; }

.newPage-card {
  margin-top: 5px;
  transition: .5s !important; }
  .newPage-card .newPage-cardImg {
    height: 200px; }
  .newPage-card .newPage-cardContent {
    padding-bottom: 10px !important; }
    .newPage-card .newPage-cardContent .newPage-cardContentTitle {
      display: block; }
    .newPage-card .newPage-cardContent .newPage-cardContentCaption {
      position: relative;
      display: block;
      height: 50px;
      overflow: hidden; }
      .newPage-card .newPage-cardContent .newPage-cardContentCaption .newPage-cardContentCaption-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(255, 0, 0, 0), rgba(255, 0, 0, 0), white); }
    .newPage-card .newPage-cardContent .newPage-cardContentPrice, .newPage-card .newPage-cardContent .newPage-cardContentTimer, .newPage-card .newPage-cardContent .newPage-cardContentLocation {
      display: flex;
      justify-content: center;
      align-items: center; }
      .newPage-card .newPage-cardContent .newPage-cardContentPrice *, .newPage-card .newPage-cardContent .newPage-cardContentTimer *, .newPage-card .newPage-cardContent .newPage-cardContentLocation * {
        margin: 5px; }
      .newPage-card .newPage-cardContent .newPage-cardContentPrice button, .newPage-card .newPage-cardContent .newPage-cardContentTimer button, .newPage-card .newPage-cardContent .newPage-cardContentLocation button {
        height: 30px; }
    .newPage-card .newPage-cardContent .newPage-cardContentPrice {
      margin-bottom: 10px; }
    .newPage-card .newPage-cardContent .newPage-cardContentLocation {
      padding: 5px;
      border-top: 1px dashed #d9d9d9; }
  .newPage-card:hover {
    margin-top: 0px;
    box-shadow: 0px 5px 4px 0px lightgrey;
    cursor: pointer; }

.shadow-box {
  width: 100%;
  display: flex;
  height: 100px;
  border-radius: 15%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 5px 0px #666666;
  background-color: #fafafF; }

.gallery-wrapper {
  position: fixed;
  z-index: 10000; }
  .gallery-wrapper :first-child {
    background-color: rgba(0, 0, 0, 0.85) !important; }
  .gallery-wrapper .gallery-caption {
    position: fixed;
    color: white;
    width: 100%;
    bottom: 0;
    left: 0; }
  .gallery-wrapper iframe {
    min-width: 80%;
    min-height: 80%;
    background-color: transparent !important; }


/* #home .testimonials .slide {
  padding-left: 100px;
  padding-right: 100px;
} */

.page-loading {
  display: block;
  background: rgba(0,0,0,.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.page-loading > img {
  width: 100px;
  position: absolute;
  top: calc(100vh / 2 - 50px);
  left: calc((100vw / 2) - 50px);
}

@media (min-width: 960px) {
  #home .carousel .control-dots {
    bottom: 45px;
  }
}

.FlexContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  flex-direction: row;
}

.FlexContainer div {
  width: auto;
  margin: 0px;
}

.MuiDialog-paperWidthSm {
  min-width: 420px;
}