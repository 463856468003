@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  font-family: 'Raleway', sans-serif;
  font-size: 16px !important;

  @media (max-width: 679px) {
    font-size: 14px !important;
  }
}

@import './utils';
@import './faq';
@import './getfunded';
@import './contact';
@import './blog';
