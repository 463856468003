.break {
  flex-basis: 100%;
  height: 0;
}

.mui-justify-center {
  justify-content: center;
}

.mui-justify-between {
    justify-content: space-between;
}

.d-block {display: block !important;}
.width-100 {width: 100% !important;}

.p-1 { padding: 1em; }
.p-2 { padding: 2em; }
.p-3 { padding: 3em; }
.p-4 { 
    padding: 4em; 

    @media (max-width: 679px) {
        padding: 2em;
    }
}

.py-5 { padding: 5em 0; }
.py-4 { padding: 4em 0; }
.py-3 { padding: 3em 0; }
.py-2 { padding: 2em 0; }
.py-1 { padding: 1em 0; }

.px-1 {padding-left: 1em;padding-right: 1em;}
.px-2 {padding-left: 2em;padding-right: 2em;}
.px-3 {padding-left: 3em;padding-right: 3em;}
.px-4 {padding-left: 4em;padding-right: 4em;}

.pt-1 {padding-top: 1em;}
.pb-2 {padding-top: 2em;}
.pb-3 {padding-top: 3em;}

.mb-1 {margin-bottom: 1em !important;}
.mb-2 {margin-bottom: 2em;}

.mt-2 {margin-top: 2em;}
.my-2 {margin-top: 2em;margin-bottom: 2em;}

.mt-2 {margin-top: 2em !important;}

.mr-1 {margin-right: 1em !important;}

.text-center {text-align: center;}
.text-right {text-align: right;}
.text-left {text-align: left;}

.text-white {color: #FFF !important;}

.title {
  color: #023E8A;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5em;

  &.center {
      text-align: center;
  }

  @media (max-width: 679px) {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

.btn-kb {
  font-family: 'Roboto', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  border-radius: 50px;
  min-width: 250px;
  height: 50px;
  box-shadow: none;
  border: none;

  &:hover {
      cursor: pointer;
  }

  &.btn-blue {
      background-color: #0C74DD;
      color: white;

      &:hover {
          background-color: darken(#0C74DD, 10%);
      }
  }

  &.btn-orange {
      background-color: #F9AB2E;
      color: white;

      &:hover {
          background-color: darken(#F9AB2E, 10%);
      }
      &:disabled {
        background-color: darken(#F9AB2E, 15%);
        cursor: default;
      }
  }

  &.btn-sm {
      font-size: 1.2rem;
      min-width: 300px;
      height: 50px;
  }

  &.btn-xl {
      font-size: 1.65em;
      min-width: 350px;
      height: 80px;
  }

  &.btn-full {
      display: block;
      width: 100%;
  }

  @media(max-width: 1366px) {
      min-width: 200px;
      height: 60px;
      font-size: 1.25rem;

      &.btn-xl {
          min-width: 275px;
          height: 70px;
          font-size: 1.5rem;
      }
  }
  
  @media(max-width: 640px) {
      min-width: 250px;
      height: 50px;
      font-size: 1.25rem;

      &.btn-xl {
        min-width: 200px;
        height: 50px;
        font-size: 1.5rem;
      }
  }
}

.btn-rounded {border-radius: 50px !important;}
.btn-link {
    background: none;
    border: none;
    color: #0D44AC;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.313rem;

    &:hover {
        cursor: pointer;
        color: #023E8A;
    }
}

.MuiAlert-message {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 1.5rem;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: #fff !important;
}
.MuiSelect-outlined.MuiSelect-outlined:focus {
    background-color: #fff;
}

.input-full-width {
    width: 100%;
}